@import '../../node_modules/bootstrap/scss/_functions.scss';
@import '../../node_modules/bootstrap/scss/_variables.scss';
@import '../../node_modules/bootstrap/scss/_mixins.scss';

.flagIcon {
  margin-right: 0px;
}

@include media-breakpoint-up(md) {
  .flagIcon {
    margin-right: 10px;
  }
}
