//
// _menu.scss
// 

$sidebar-bg: rgb(243, 245, 246);
$sidebar-menu-item-color: rgb(0, 144, 208);
$sidebar-menu-item-bg-color: #fff;

$sidebar-menu-sub-item-color: rgb(25, 38, 48);
$sidebar-menu-item-icon-color: rgb(25, 38, 48);
$sidebar-menu-item-hover-color: #005896;
$sidebar-menu-item-hover-bg-color:rgb(234, 236, 238);
$sidebar-menu-item-active-color: #fff;
$sidebar-menu-item-active-bg-color: rgb(0, 144, 208);

$sidebar-menu-item-border-color: rgb(228, 230, 232);

$sidebar-width:  250px;
$header-height: 91px;

$font-family-secondary: 'Arial', sans-serif;
$font-weight-medium: 500;

$box-shadow: none;

.metismenu {
  margin: 0;

  li {
    display: block;
    width: 100%;
  }

  .mm-collapse {
    display: none;

    &:not(.mm-show) {
      display: none;
    }

    &.mm-show {
      display: block
    }
  }

  .mm-collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-timing-function: ease;
    transition-duration: .35s;
    transition-property: height, visibility;
  }
}


.vertical-menu {
  width: $sidebar-width;
  z-index: 1001;
  background: $sidebar-bg;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: $header-height;
  box-shadow: $box-shadow;
}

.main-content {
  width: 100%;
}

#sidebar-menu {
  padding: 16px 8px 32px 8px;

  .mm-active {
    >.has-arrow {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }

  .has-arrow {
    &:after {
      content: '';
      font-family: o2icons;
      display: block;
      float: right;
      transition: transform .2s;
      font-size: 1rem;
    }
  }

  ul {
    li {
      a, button {
        display: block;
        padding: 12px 20px;
        font-family: $font-family-secondary;
        color: $sidebar-menu-item-color;
        background-color: $sidebar-menu-item-bg-color;
        position: relative;
        font-size: 16px;
        transition: all .4s;
        text-decoration: none;
        border-left: 1px solid $sidebar-menu-item-border-color;
        border-right: 1px solid $sidebar-menu-item-border-color;
        border-bottom: 1px solid $sidebar-menu-item-border-color;

        i {
          display: inline-block;
          min-width: 1.75rem;
          padding-bottom: .125em;
          line-height: 1.40625rem;
          vertical-align: middle;
          color: $sidebar-menu-item-icon-color;
          transition: all .4s;
          font-size: 14.7px;
        }

        &:hover {
          color: $sidebar-menu-item-hover-color;
          background-color: $sidebar-menu-item-hover-bg-color;

          i {
            color: $sidebar-menu-item-hover-color;
            background-color: $sidebar-menu-item-hover-bg-color;
          }
        }
      }
      a.first {
        border-top: 1px solid $sidebar-menu-item-border-color;
      }

      .badge {
        margin-top: 4px;
      }

      ul.sub-menu {
        padding: 0;

        li {

          a {
            padding: 12px 20px 12px 36px;
            font-size: 14px;
            color: $sidebar-menu-sub-item-color;
          }

          ul.sub-menu {
            padding: 0;

            li {
              a {
                padding: .4rem 1.5rem .4rem 4.5rem;
                font-size: 13.5px;
              }
            }
          }
        }
      }
    }
  }
}

.menu-title {
  padding: 12px 20px !important;
  letter-spacing: .05em;
  pointer-events: none;
  cursor: default;
  font-size: 10px;
  text-transform: uppercase;
  color: $sidebar-menu-item-icon-color !important;
  font-weight: $font-weight-medium;
}

.mm-active {
  color: $sidebar-menu-item-active-color !important;

  .active {
    color: $sidebar-menu-item-active-color !important;

    i {
      color: $sidebar-menu-item-active-color !important;
    }
  }
  > i {
    color: $sidebar-menu-item-active-color !important;
  }
}

ul.mm-active {
  > li > a.mm-active {
    color: $sidebar-menu-item-hover-color !important;
    background-color: $sidebar-bg !important;
  }
}

ul.mm-active {
  > li > a.mm-active:not(.has-arrow) {
    color: $sidebar-menu-item-active-color !important;
    background-color: $sidebar-bg !important;
  }
}

ul.mm-active {
  li.mm-active {
    a.mm-active {
      background-color: $sidebar-menu-item-active-bg-color !important;
    }

    a.mm-active.has-arrow  {
      color: $sidebar-menu-item-hover-color !important;
      background-color: $sidebar-bg !important;
    }
  }
}

/* setup assistant link */
#sidebar-menu {
  ul {
    li {
      .special-links {
        width: 100%;
        text-align: left;
        background-color: #fdd7db;
        border-color: #fcc7cd;
        color: #7f1c28;
        border-top: none;
        outline: 0;

        &:hover {
          color: #621821;
          background-color: #f7cfd3;
        }
      }
    }
  }
}
