$o2-dark-blue: #000066;
$o2-blue: #0019a5;
$o2-medium-blue: #0090d0;
$o2-light-blue: #41b6e6;
$o2-turquoise: #b1e4e3;
$o2-white: #ffffff;

$o2-purple: #953698;
$o2-pink: #e45dbf;
$o2-red: #f4364c;
$o2-orange: #ff7f41;
$o2-yellow: #fedb00;
$o2-green: #91c90e;
$o2-sea-green: #01b7b4;

$badge-green: #75a30a;
$badge-grey: #717171;

$o2-notify-blue: rgba(0, 144, 208, 0.4);
$o2-notify-bg-blue: rgba(0, 144, 208, 0.1);

$o2-notify-green: rgba(145, 201, 14, 0.7);
$o2-notify-green-text: #668c0e;
$o2-notify-bg-green: rgba(145, 201, 14, 0.3);
