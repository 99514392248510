@import '../scss/_o2-colours.scss';

$theme-colors: (
  "primary": $o2-medium-blue,
  "active": $o2-green,
  "inactive": $o2-red,
  "refresh": $o2-light-blue,
  "warning": $o2-orange,
  "danger": $o2-red,
);

$border-radius: 3px !default;
