@import 'variables';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import 'menu';
@import 'custom-scrollbar';
@import 'pages';

:root {
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, "Segoe UI", Roboto, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

body {
  font-family: var(--font-family-sans-serif);
}

.btn:focus {
  box-shadow: none;
}

/* tabs */
$tabs-nav-item-hover-color: #005896;

nav.nav-tabs a:hover {
  color: $tabs-nav-item-hover-color;
}

.mb-2rem {
  margin-bottom: 2rem;
}
