body {
  margin: 0;
}

main {
  margin-left: 240px;
  margin-top: 91px;
  display: flex;
  align-items: stretch;
  height: calc(100vh - 91px);
}

main.without-sidebar {
  margin-left: 0;
}

@media only screen and (max-width: 767.98px) {
  main {
    margin-left: 0;
  }
  main.mobile.is-open {
    display: none;
    transition: all 0.5s;
  }

  .vertical-menu {
    width: 100%;
  }
}

.main-content {
  padding-left: 6px;
}

.main-content-special-routes {
  padding-left: 0px;
  width: 100%;
}

.content {
  padding-bottom: 60px;
}

.login-content {
  margin-top: 5rem;
}