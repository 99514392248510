@import '../scss/_o2-colours.scss';
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins/_breakpoints';

nav.nav-tabs {
  margin-top: 2rem;
}

.tab {
  padding: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.tab .tab-panel {
  padding: 1.25rem;
}

.o2-green-text {
  color: $o2-green;
}

.o2-red-text {
  color: $o2-red;
}

.toggle-switch-subheading {
  padding-top: 30px;
  padding-left: 85px;
  margin-bottom: 0;
}

.toggle-switch-small-subheading {
  padding-top: 5px;
  padding-left: 50px;
  margin-bottom: 0;
}

.btn-active {
  color: $o2-white;
}

.btn-refresh,
.btn-warning {
  color: $o2-white;
}

.o2-badge {
  position: relative;
  top: 1px;
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 95%;
  font-weight: 700;
  line-height: 1;
  white-space: normal;
  vertical-align: baseline;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.o2-badge-active {
  color: $o2-green;
  background-color: $o2-white;
  border: 1px solid $o2-green;
}

.o2-badge-inactive {
  color: $o2-red;
  background-color: $o2-white;
  border: 1px solid $o2-red;
}

.o2-badge-grey {
  color: $badge-grey;
  background-color: $o2-white;
  border: 1px solid $badge-grey;
}

.o2-badge-green {
  color: $badge-green;
  background-color: $o2-white;
  border: 1px solid $badge-green;
}

.o2-badge-inline {
  top: 0;
}

.toast-save-msg {
  margin-top: 30px;
  margin-bottom: 10px;
  max-width: 100%;
  border-color: $o2-notify-blue;
  background-color: $o2-notify-bg-blue;
}

.toast-save-msg .toast-body {
  color: $o2-medium-blue;
}

.toast-register-msg {
  margin-top: 30px;
  margin-bottom: 10px;
  max-width: 100%;
  border-color: $o2-notify-green;
  background-color: $o2-notify-bg-green;
}

.toast-register-msg .toast-body {
  color: $o2-notify-green-text;
}

.toast-save-msg-inbetween {
  margin-bottom: 30px !important;
}

.toast-table-inbetween {
  margin-top: 0;
  margin-bottom: 20px !important;
}

.alert-heading.h4 {
  font-size: 1.15rem;
}

.alert-inbetween {
  margin-top: 10px;
  margin-bottom: 30px !important;
}

.router-value {
  word-wrap: break-word;
}

.td-w-200 {
  width: 200px;
}

.tooltip-active .tooltip-inner {
  background-color: $o2-green;
}
.tooltip-active.bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip-active.bs-tooltip-left .arrow::before {
  border-left-color: $o2-green !important;
}

.tooltip-inactive .tooltip-inner {
  background-color: $o2-red;
}
.tooltip-inactive.bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip-inactive.bs-tooltip-left .arrow::before {
  border-left-color: $o2-red !important;
}

.tooltip-edit .tooltip-inner {
  background-color: $o2-medium-blue;
}
.tooltip-edit.bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip-edit.bs-tooltip-left .arrow::before {
  border-left-color: $o2-medium-blue !important;
}

.tooltip-edit-top .tooltip-inner {
  background-color: $o2-medium-blue;
}
.tooltip-edit-top.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip-edit-top.bs-tooltip-top .arrow::before {
  border-top-color: $o2-medium-blue !important;
}

.tooltip-remove .tooltip-inner {
  background-color: $o2-red;
}
.tooltip-remove.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip-remove.bs-tooltip-right .arrow::before {
  border-right-color: $o2-red !important;
}

.tooltip-ring .tooltip-inner {
  background-color: $o2-green;
}
.tooltip-ring.bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip-ring.bs-tooltip-left .arrow::before {
  border-left-color: $o2-green !important;
}

.tooltip-inbound .tooltip-inner {
  background-color: $o2-green;
}
.tooltip-inbound.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip-inbound.bs-tooltip-right .arrow::before {
  border-right-color: $o2-green !important;
}

.tooltip-missed .tooltip-inner {
  background-color: $o2-red;
}
.tooltip-missed.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip-missed.bs-tooltip-right .arrow::before {
  border-right-color: $o2-red !important;
}

.tooltip-outgoing .tooltip-inner {
  background-color: $o2-medium-blue;
}
.tooltip-outgoing.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip-outgoing.bs-tooltip-right .arrow::before {
  border-right-color: $o2-medium-blue !important;
}

.table-numbers-incoming-calls {
  font-size: 80%;
}

.table-numbers-incoming-calls td.checkboxes {
  text-align: center;
}

.field-timer {
  width: 75px;
  text-align: center;
}

.mw-300 {
  max-width: 300px;
}
.mw-200 {
  max-width: 200px;
}
.mw-100 {
  max-width: 100px;
}

.ip-address-divider {
  &:after {
    position: absolute;
    top: 7px;
    right: 0px;
    font-weight: bold;
    content: "."
  }
}

.mac-address-divider {
  &:after {
    position: absolute;
    top: 7px;
    right: 0px;
    font-weight: bold;
    content: ":"
  }
}

.wizard-step-previous {
  background-color: $o2-blue;
}

.wizard-step-current {
  background-color: $o2-medium-blue;
}

.wizard-step-next {
  background-color: $o2-light-blue;
}

.wizard-step-last {
  @include media-breakpoint-up(sm) {
    border-right: none !important;
  }
}

.inner-row {
  padding-left: 0;
  padding-right: 0;
}

td.signal {
  min-width: 180px;
}

td.status {
  width: 100px !important;
}
td.only-checkbox {
  width: 50px !important;
}

.wifi-large {
  font-size: 1.5rem
}

@include media-breakpoint-between(xs, sm) {
  .footer-buttons {
    margin-top: 0.5rem !important;
  }
}

@include media-breakpoint-between(sm, md) {
  .footer-left-button {
    margin-left: 0.5rem !important;
  }
}
